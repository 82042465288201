
// An object full of lil device size/type helpers.

// Constants
const MOBILE_MAX = 450, TABLET_MAX = 960

const d = {

  // returns true if script is being interpreted by a browser
  isBrowser: () =>
    typeof window !== "undefined",

  // returns true if the device is (or is sized as) a mobile phone
  isMobile: () =>
    d.isBrowser() && window.innerWidth <= MOBILE_MAX,

  // returns true if the device is (or is sized as) a tablet
  isTablet: () =>
    d.isBrowser() && !d.isMobile() && window.innerWidth <= TABLET_MAX,

  // returns true if the device is (or is sized as) as desktop/laptop PC
  isDesktop: () =>
    d.isBrowser() && !d.isMobile() && !d.isTablet(),

  // returns true if the device is in portait mode
  isPortrait: () =>
    d.isBrowser() && window.innerWidth < window.innerHeight,

  // returns true if the device is in landscape mode
  isLandscape: () =>
    d.isBrowser() && !d.isPortrait(),

  // this is just a shorthand ternary operator. The first arg
  // is returned if the device is a phone, the second arg is
  // returned otherwise.
  ifMobile: (ifTrue=true, ifFalse=false) =>
    d.isMobile() ? ifTrue : ifFalse,

}

export default d
