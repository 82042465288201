import React from "react"
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import style from "./Arrows.module.scss"

const ARROW_SIZE = 32

export const nextArrow = (
  <div>
    <ArrowRightOutlined
      className={style.arrow}
      style={{ fontSize: ARROW_SIZE, right: ARROW_SIZE, top: ARROW_SIZE / -2 }}
    />
    <ArrowRightOutlined
      className={style.arrowShadow}
      style={{ fontSize: ARROW_SIZE, right: ARROW_SIZE, top: ARROW_SIZE / -2 }}
    />
  </div>
)

export const prevArrow = (
  <div>
    <ArrowLeftOutlined
      className={style.arrow}
      style={{ fontSize: ARROW_SIZE, left: ARROW_SIZE, top: ARROW_SIZE / -2 }}
    />
    <ArrowLeftOutlined
      className={style.arrowShadow}
      style={{ fontSize: ARROW_SIZE, left: ARROW_SIZE, top: ARROW_SIZE / -2 }}
    />
  </div>
)

export const close = (
  <div>
    <CloseOutlined className={style.close} style={{ fontSize: ARROW_SIZE }} />
    <CloseOutlined
      className={style.closeShadow}
      style={{ fontSize: ARROW_SIZE }}
    />
  </div>
)
