import React from "react"
import { Carousel, Modal, Tag } from "antd"
import Img from "gatsby-image"
import style from "./Lightbox.module.scss"
import MediaContext from "@avldev/gatsby-theme-core-ui/src/components/MediaContext"
import { nextArrow, prevArrow, close } from "../PhotoGallery/Arrows"

class Lightbox extends React.Component {
  static contextType = MediaContext

  constructor(props) {
    super(props)
    this.state = {
      currentPhoto: 0,
    }
  }

  render() {
    const { currentPhoto, onCancel, parent, photos, vis } = this.props

    return (
      <Modal
        bodyStyle={{
          padding: 0,
        }}
        centered={true}
        className={style.modal}
        closeIcon={close}
        destroyOnClose
        footer={null}
        maskStyle={{ background: `rgba(0,0,0,0.85)` }}
        onCancel={onCancel}
        visible={vis}
        width="90vw"
      >
        <Tag className={style.tag} color="rgba(0,0,0,0.8)">
          {`${this.state.currentPhoto + 1} of ${photos.length}`}
        </Tag>
        <Carousel
          adaptiveHeight
          arrows={() => photos.length > 1}
          beforeChange={(cur, next) => this.setState({ currentPhoto: next })}
          dots={false}
          draggable={true}
          effect={this.context.reduceMotion ? `fade` : `scrollx`}
          initialSlide={currentPhoto}
          nextArrow={nextArrow}
          prevArrow={prevArrow}
          swipeToSlide
          asNavFor={parent || null}
        >
          {photos.map((photo, index) => (
            <div className={style.imageWrapper} key={index}>
              <Img alt={photo.src} className={style.image} fluid={photo} />
            </div>
          ))}
        </Carousel>
      </Modal>
    )
  }
}

export default Lightbox
