import React from "react"
import { Affix, Col, Row } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import RoomType from "../components/RoomType/RoomType"

const description = `From studios to three bedroom suites, Residences at Biltmore has room for everyone. Guests will enjoy all that Biltmore and downtown Asheville has to offer!`

const row = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    marginLeft: `auto`,
    marginRight: `auto`,
    marginTop: 64,
    maxWidth: 1360,
  },
}

const col = {
  span: 24,
}

const listRooms = rooms => {
  return rooms.map((room, index) => <RoomType key={index} {...room} />)
}

export default ({ data, location }) => {
  // bump exec suite to the bottom of the list
  const rooms = [...data.rooms.nodes.slice(1), data.rooms.nodes[0]]
  const { hero } = data

  const jsonld = rooms.map(room => ({
    "@context": "http://schema.org",
    "@type": "HotelRoom",
    name: room.roomType,
    description: room.description.description,
    image: room.interiors ? room.interiors[0].fluid.src : null,
    petsAllowed: "http://schema.org/True",
    smokingAllowed: "http://schema.org/False",
    url: `${process.env.GATSBY_SITE_URL}/rooms#${room.roomType.replace(
      / /g,
      "-"
    )}`,
    yearBuilt: "2019",
    accommodationFloorPlan: {
      "@type": "FloorPlan",
      numberOfBathroomsTotal: 1,
      numberOfRooms: room.roomType.includes("Suite") ? 2 : 1,
    },
    bed: {
      "@type": "BedDetails",
      numberOfBeds: room.roomType.includes("Queen") ? 2 : 1,
      typeOfBed: room.roomType.includes("Queen") ? "Queen" : "King",
    },
    floorSize: {
      "@type": "QuantitativeValue",
      value: room.squareFeet,
      unitCode: "FTK",
    },

    occupancy: {
      "@type": "QuantitativeValue",
      maxValue: room.sleeps.toString(),
      unitCode: "C62",
    },
  }))

  const heroProps = {
    caption: `More than a room. A better home away from home.`,
    contentfulAsset: hero,
    imageStyle: { filter: `brightness(0.75)` },
  }

  const handleAffixChange = (affixed, elemID) => {
    if (typeof document !== "undefined") {
      const bar = document.getElementById(elemID)
      if (affixed) {
        bar.classList.add("add-box-shadow")
      } else {
        bar.classList.remove("add-box-shadow")
      }
    }
  }

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        schema={JSON.stringify(jsonld)}
        title="Hotel Suites & Lodging in Asheville NC - The Residences at Biltmore"
        url={location.href}
      />
      <Hero {...heroProps} />
      <Affix
        offsetTop={0}
        onChange={affix => handleAffixChange(affix, "booking-bar")}
      >
        <BookingBar showCTAButtons={false} />
      </Affix>
      <Row {...row}>
        <Col {...col}>{listRooms(rooms)}</Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    ...AllHotelRooms
    hero: contentfulAsset(title: { eq: "RAB - Studio Hero" }) {
      ...HeroFluid
    }
  }
`
